import { CommonModule } from "@angular/common";
import { Component, inject, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { MediaUrlPipe } from "@pipes/media-url.pipe";
import { COLORS } from "@variables/globals";
import { AvatarModule } from "primeng/avatar";
import { TooltipModule } from "primeng/tooltip";

@Component({
  selector: "app-avatar-meta",
  standalone: true,
  imports: [CommonModule, AvatarModule, MediaUrlPipe, TooltipModule],
  templateUrl: "./avatar-meta.component.html",
  styleUrls: ["./avatar-meta.component.scss"],
})
export class AvatarMetaComponent implements OnChanges {
  @Input() type: "company" | "forum" | "user" = "user";
  @Input() title = "";
  @Input() description = "";
  @Input() extra = "";
  @Input() avatar = "";
  @Input() defaultAvatar = "";
  @Input() size: "small" | "medium" | "normal" | "large" | "xlarge" | "full" = "normal";
  @Input() imgSize: "small" | "normal" | "large" | "xlarge" | "full" | undefined;
  @Input() labelDirection: "row" | "col" = "col";
  @Input() labelClass = "";
  @Input() titleClass = "";
  @Input() descriptionClass = "";
  @Input() containerClass = "";
  @Input() labelContainerClass = "";
  @Input() extraClass = "";
  @Input() customDescription = false;
  @Input() tooltip = false;
  @Input() fallBackLabel = "";
  @Input() backgroundColor: string | number | undefined = "transparent";

  private readonly sanitizer = inject(DomSanitizer);
  defaultAvatarHtml?: SafeHtml;
  color = "var(--primary-DEFAULT)";

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["defaultAvatar"]?.currentValue) {
      this.defaultAvatarHtml = this.sanitizer.bypassSecurityTrustHtml(
        changes["defaultAvatar"].currentValue
      );
    } else {
      this.defaultAvatarHtml = undefined;
    }
    if (changes["backgroundColor"]?.currentValue) {
      if (typeof changes["backgroundColor"]?.currentValue == "number")
        this.color = this.rainbow(changes["backgroundColor"]?.currentValue);
      else if (typeof changes["backgroundColor"]?.currentValue == "string")
        this.color = changes["backgroundColor"]?.currentValue;
      else this.color = "var(--primary-DEFAULT)";
    }
  }

  error(event: Event) {
    if (event.target)
      if (this.type === "company")
        // @ts-ignore
        event.target.src = "/assets/logo/empty-company.svg";
      // @ts-ignore
      else if (this.type === "user") event.target.src = "/assets/logo/empty-user.svg";
      // @ts-ignore
      else event.target.src = "/assets/logo/empty-community.svg";
  }

  rainbow(id?: number) {
    return COLORS[(id ?? 0) % 10];
  }
}
