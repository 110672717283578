import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";

import queryString from "query-string";
import { BehaviorSubject } from "rxjs";

import { NetworkStatusService } from "./network-status.service";
import { WidthService } from "./width.service";

@Injectable({
  providedIn: "root",
})
export class SplitService {
  private readonly routerService = inject(Router);
  private readonly widthService = inject(WidthService);
  private readonly secondScreen = new BehaviorSubject<string>("");

  readonly networkService = inject(NetworkStatusService);
  readonly secondScreenListener = this.secondScreen.asObservable();

  splitWithSize(
    url: (string | number)[],
    query?: Record<string, number | string | boolean>,
    callback?: (arg0: boolean) => void
  ) {
    if (this.networkService.isOnline()) {
      if (this.widthService.getWidth() >= 1200) {
        if (query) this.secondScreen.next("/" + url.join("/") + "?" + queryString.stringify(query));
        else this.secondScreen.next("/" + url.join("/"));
        callback?.(true);
      } else {
        this.routerService.navigate(url, { queryParams: query });
        callback?.(false);
      }
    }
  }

  splitWhenSize(
    url: (string | number)[],
    query?: Record<string, number | string | boolean>,
    callback?: (arg0: boolean) => void
  ) {
    if (this.networkService.isOnline()) {
      if (this.widthService.getWidth() >= 1200) {
        if (query) this.secondScreen.next("/" + url.join("/") + "?" + queryString.stringify(query));
        else this.secondScreen.next("/" + url.join("/"));
        callback?.(true);
      }
    }
  }

  getSplitView() {
    return this.secondScreen.value;
  }
  clearSplit() {
    this.secondScreen.next("");
  }
  navigate(isFromSecondScreen: boolean, url: (string | number)[]) {
    if (this.networkService.isOnline())
      if (!isFromSecondScreen) {
        this.routerService.navigate(url);
      } else {
        this.secondScreen.next("/" + url.join("/"));
      }
  }
}
