import { inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { MessageService } from "primeng/api";

import { TranslationService } from "./translation.service";

@Injectable({
  providedIn: "root",
})
export class SnackbarService {
  private readonly snackbar = inject(MessageService);
  private readonly translationService = inject(TranslationService);

  translations: Record<string, string> = {};

  constructor() {
    this.translationService
      .get("snackbar")
      .pipe(takeUntilDestroyed())
      .subscribe(val => (this.translations = val));
  }

  clear(id: string) {
    this.snackbar.clear(id);
  }

  error(msg: string, title?: string, translationKey?: string) {
    this.snackbar.add({
      severity: "error",
      summary: title ? title : this.translations["error"] ?? "Error",
      detail: msg
        ? translationKey
          ? msg + " " + this.translations[translationKey]
          : msg
        : translationKey
        ? this.translations[translationKey]
        : "",
      life: 100000,
    });
  }
  info(msg: string, title?: string, translationKey?: string, data?: any) {
    this.snackbar.add({
      severity: "info",
      summary: title ? title : this.translations["info"] ?? "Info",
      detail: msg
        ? translationKey
          ? msg + " " + this.translations[translationKey]
          : msg
        : translationKey
        ? this.translations[translationKey]
        : "",
      data,
    });
  }
  warn(msg: string, title?: string, translationKey?: string) {
    this.snackbar.add({
      severity: "warn",
      summary: title ? title : this.translations["warning"] ?? "Warning",
      detail: msg
        ? translationKey
          ? msg + " " + this.translations[translationKey]
          : msg
        : translationKey
        ? this.translations[translationKey]
        : "",
    });
  }
  success(msg: string, title?: string, translationKey?: string) {
    this.snackbar.add({
      severity: "success",
      summary: title ? title : this.translations["success"] ?? "Success",
      detail: msg
        ? translationKey
          ? msg + " " + this.translations[translationKey]
          : msg
        : translationKey
        ? this.translations[translationKey]
        : "",
    });
  }

  online() {
    this.snackbar.clear("offline");
    this.snackbar.add({
      severity: "success",
      key: "online",
      summary: this.translations["online"] ?? "Connection restored! Please continue.",
    });
  }

  offline() {
    this.snackbar.clear("online");
    this.snackbar.add({
      severity: "error",
      life: 1000000,
      key: "offline",
      summary: this.translations["offline"] ?? "No internet connection",
    });
  }
}
