import { inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { BehaviorSubject, combineLatest } from "rxjs";

import { AuthService } from "./auth.service";
import { WorkspaceService } from "./workspace.service";

@Injectable({
  providedIn: "root",
})
export class SectorService {
  private readonly authService = inject(AuthService);
  private readonly workspaceService = inject(WorkspaceService);

  education = new BehaviorSubject<boolean>(false);
  educationListener = this.education.asObservable();

  constructor() {
    combineLatest({
      company: this.authService.company,
      workspace: this.workspaceService.active,
    })
      .pipe(takeUntilDestroyed())
      .subscribe(({ company, workspace }) => {
        if (workspace?.sector) {
          if (workspace?.sector === "education") this.education.next(true);
          else this.education.next(false);
        } else if (company?.sector) {
          if (company?.sector === "education") this.education.next(true);
          else this.education.next(false);
        } else this.education.next(false);
      });
  }
}
