import { HttpClient } from "@angular/common/http";
import { DestroyRef, inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";

import {
  BackendResponse,
  Company,
  CompanyWorkspace,
  Invitation,
  Media,
  MetaCountBackendResponse,
  SubscriptionPlan,
  User,
  Workspace,
  WorkspaceMember,
} from "@models/models";
import { environment } from "@src/environments/environment";
import queryString from "query-string";
import { BehaviorSubject, first, map, of, switchMap, tap } from "rxjs";

import { AuthService } from "./auth.service";
import { LocalStorageService } from "./local-storage.service";

@Injectable({
  providedIn: "root",
})
export class WorkspaceService {
  private readonly url = environment.backendUrl;

  private readonly httpService = inject(HttpClient);
  private readonly authService = inject(AuthService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly routerService = inject(Router);

  private readonly workspacesListener = new BehaviorSubject<Workspace[]>([]);
  readonly workspaces = this.workspacesListener.asObservable();
  readonly active = new BehaviorSubject<CompanyWorkspace>({
    id: null,
    name: "",
    img: "",
    primaryColor: "",
    darkThemePrimaryColor: "",
    company: false,
    sector: "",
  });

  loading = false;
  submitting = false;
  userId?: number;
  company?: Company;

  constructor() {
    this.authService.user.pipe(takeUntilDestroyed()).subscribe(val => (this.userId = val?.id));
    this.authService.company
      .pipe(
        tap(val => {
          this.company = val;
          this.loading = true;
        }),
        switchMap(val => {
          if (val)
            return this.httpService
              .get<BackendResponse<Workspace[]>>(`${this.url}/workspaces`)
              .pipe(map(val => (val.message === "success" ? val.result : [])));
          return of([]);
        })
      )
      .pipe(takeUntilDestroyed())
      .subscribe(val => {
        this.workspacesListener.next(val);
        this.loading = false;
        const saved = JSON.parse(this.localStorageService.getData("workspace") ?? "{}");
        if (saved["name"]) {
          if (saved["company"]) this.setColor("#000000");
          else this.setColor(saved["primaryColor"] ?? "#009688");
          this.active.next(saved);
        } else {
          if (!this.company) {
            this.clear();
            this.active.next({
              id: null,
              name: "",
              img: "",
              primaryColor: "",
              darkThemePrimaryColor: "",
              company: false,
              sector: "",
            });
          } else {
            const saved = JSON.parse(this.localStorageService.getData("workspace") ?? "{}");
            if (saved["name"]) this.active.next(saved);
            else {
              if (val.length > 0) {
                this.switchWorkspace(val[0]);
              } else {
                this.active.next({
                  id: null,
                  company: true,
                  name: this.company?.name ?? "",
                  img: this.company?.logoUrl ?? "",
                  sector: this.company?.sector ?? "business",
                });
              }
            }
          }
        }
      });
  }

  setColor(value: string) {
    let color = value;
    if (!color.startsWith("#")) color = "#" + color;
    document.documentElement.style.setProperty("--primary-DEFAULT", color);
    document.documentElement.style.setProperty("--primary-color", color);
  }

  clear() {
    this.localStorageService.removeData("workspace");
    this.workspacesListener.next([]);
  }

  switchWorkspace(workspace: Workspace | null) {
    if (
      (!this.active.value.company && !workspace) ||
      (workspace && this.active.value.name !== workspace.name)
    )
      this.httpService
        .post<BackendResponse<{ accessToken: string }>>(`${this.url}/workspaces/switch`, {
          workspaceId: workspace ? workspace.id : null,
        })
        .pipe(takeUntilDestroyed(this.destroyRef), first())
        .subscribe(val => {
          if (val.message === "success") {
            this.authService.changeAccessTokenWorkspace(val.result.accessToken);
            if (!workspace) {
              this.active.next({
                id: null,
                company: true,
                name: this.company?.name ?? "",
                img: this.company?.logoUrl ?? "",
                sector: this.company?.sector ?? "business",
              });
              this.localStorageService.saveData(
                "workspace",
                JSON.stringify({
                  id: null,
                  company: true,
                  name: this.company?.name ?? "",
                  img: this.company?.logoUrl ?? "",
                  sector: this.company?.sector ?? "business",
                })
              );
              this.setColor("#000000");
            } else {
              this.active.next({
                id: workspace.id,
                company: false,
                name: workspace.name ?? "",
                img: workspace.avatar?.url ?? "",
                primaryColor: workspace.primaryColor,
                darkThemePrimaryColor: workspace.darkThemePrimaryColor,
                sector: workspace?.sector ?? this.company?.sector ?? "business",
              });
              this.localStorageService.saveData(
                "workspace",
                JSON.stringify({
                  id: workspace.id,
                  company: false,
                  name: workspace.name ?? "",
                  img: workspace.avatar?.url ?? "",
                  primaryColor: workspace.primaryColor,
                  darkThemePrimaryColor: workspace.darkThemePrimaryColor,
                  sector: workspace?.sector ?? this.company?.sector ?? "business",
                })
              );
              if (workspace.primaryColor) this.setColor(workspace.primaryColor ?? "#009688");
              else this.setColor("#009688");
            }
          }
        });
  }

  createWorkspace(
    companyId: number,
    name: string,
    description: string,
    uId: string,
    avatar: Media,
    primaryColor?: string,
    darkThemePrimaryColor?: string,
    isPrivate?: boolean,
    isPremium?: boolean
  ) {
    this.submitting = true;
    return this.httpService
      .post<BackendResponse<Workspace>>(`${this.url}/workspaces`, {
        companyId,
        name,
        description,
        uId,
        avatarId: avatar.id,
        primaryColor,
        darkThemePrimaryColor,
        isPrivate: isPrivate ?? false,
        isPremium: isPremium ?? false,
      })
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        first(),
        tap({
          error: () => (this.submitting = false),
          next: val => {
            if (val.message === "success") {
              this.createWorkspaceMember(val.result.id)
                .pipe(takeUntilDestroyed(this.destroyRef), first())
                .subscribe({
                  error: () => (this.submitting = false),
                  next: () => {
                    this.submitting = false;
                    // if (va.message === "success")
                    const workspace = { ...(val.result ?? {}), avatar };
                    this.workspacesListener.next([
                      ...(this.workspacesListener.value ?? []),
                      workspace,
                    ]);
                    this.switchWorkspace(workspace);
                    this.routerService.navigate([]);
                  },
                });
            }
          },
        })
      );
  }

  updateWorkspace(
    id: number,
    name: string,
    description: string,
    uId: string,
    avatar: Media,
    primaryColor?: string,
    darkThemePrimaryColor?: string,
    isPrivate?: boolean,
    isPremium?: boolean
  ) {
    this.submitting = true;
    return this.httpService
      .put<BackendResponse<Workspace>>(`${this.url}/workspaces/${id}`, {
        name,
        description,
        uId,
        avatarId: avatar.id,
        primaryColor,
        darkThemePrimaryColor,
        isPrivate: isPrivate ?? false,
        isPremium: isPremium ?? false,
      })
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        first(),
        tap({
          error: () => (this.submitting = false),
          next: val => {
            this.submitting = false;
            if (val.message === "success")
              this.workspacesListener.next(
                (this.workspacesListener.value ?? []).map(workspace => {
                  if (id !== workspace.id) return workspace;
                  else return { ...(val.result ?? {}), avatar };
                })
              );
          },
        })
      );
  }

  createWorkspaceMember(workspaceId: number) {
    return this.httpService.post<WorkspaceMember>(`${this.url}/workspace/members`, {
      workspaceId,
      userId: this.userId,
      role: "admin",
    });
  }

  getMembersByRole(workspaceId: number, page: number, limit: number, role: string) {
    return this.httpService.get<MetaCountBackendResponse<WorkspaceMember>>(
      `${this.url}/workspace/members/workspace?workspaceId=${workspaceId}&page=${page}&limit=${limit}&role=${role}`
    );
  }

  inviteWorkspaceMember(userId: number, workspaceId: number) {
    return this.httpService.post<BackendResponse<WorkspaceMember>>(
      `${this.url}/workspace/members/invite`,
      {
        userId,
        workspaceId,
        role: "member_pending",
      }
    );
  }

  deleteWorkspaceMember(workspaceMemberId: number) {
    return this.httpService.delete<BackendResponse<WorkspaceMember>>(
      `${this.url}/workspace/members/${workspaceMemberId}`
    );
  }

  editWorkspaceMember(workspaceMemberId: number, role: string) {
    return this.httpService.put<BackendResponse<WorkspaceMember>>(
      `${this.url}/workspace/members/${workspaceMemberId}`,
      {
        role: role,
      }
    );
  }

  getUserByEmail(email: string) {
    return this.httpService.post<BackendResponse<User>>(`${this.url}/users/email`, { email });
  }

  qrWorkspace(workspaceId: number) {
    return this.httpService.get(`${this.url}/workspaces/qr/${workspaceId}`, {
      responseType: "blob",
    });
  }

  getSubscriptionPlans() {
    return this.httpService.get<BackendResponse<SubscriptionPlan[]>>(
      `${this.url}/subscription/plans`
    );
  }

  createSubscriptionPlan(
    name: string,
    price: number,
    features: Record<string, string>,
    description: string,
    month: number,
    day: number,
    isTrial?: boolean
  ) {
    return this.httpService.post<BackendResponse<SubscriptionPlan>>(
      `${this.url}/subscription/plans`,
      { name, price, features, description, month, day, isTrial, subscriptionInterval: "monthly" }
    );
  }

  editSubscriptionPlan(
    planId: number,
    name: string,
    price: number,
    features: Record<string, string>,
    description: string,
    isActive: boolean,
    month: number,
    day: number,
    isTrial?: boolean
  ) {
    return this.httpService.put<BackendResponse<SubscriptionPlan>>(
      `${this.url}/subscription/plans/${planId}`,
      {
        name,
        price,
        features,
        description,
        isActive,
        month,
        day,
        isTrial,
        subscriptionInterval: "monthly",
      }
    );
  }

  deleteSubscriptionPlan(planId: number) {
    return this.httpService.delete<BackendResponse<SubscriptionPlan>>(
      `${this.url}/subscription/plans/${planId}`
    );
  }

  getSingleWorkspaceMember(workpsaceMemberId: number) {
    return this.httpService.get<BackendResponse<WorkspaceMember>>(
      `${this.url}/workspace/members/${workpsaceMemberId}`
    );
  }

  listInvitations(workspaceId: number) {
    return this.httpService.get<BackendResponse<Invitation[]>>(
      `${this.url}/invitations?${queryString.stringify({ workspaceId })}`
    );
  }

  createInvitation(workspaceId: number, role: string) {
    return this.httpService.post<BackendResponse<Invitation>>(`${this.url}/invitations`, {
      role,
      workspaceId,
    });
  }

  deleteInvitation(invitationId: number) {
    return this.httpService.delete<BackendResponse<Invitation[]>>(
      `${this.url}/invitations/${invitationId}`
    );
  }

  qrInvitaion(id: number) {
    return this.httpService.get(`${this.url}/invitations/qr/${id}`, {
      responseType: "blob",
    });
  }

  getDownloadLink(url: string) {
    return this.httpService.get(url, { responseType: "blob" });
  }

  getInvitationFromCode(code: string) {
    return this.httpService.get<BackendResponse<Invitation>>(
      `${this.url}/invitations/code/${code}`
    );
  }

  acceptCode(invitationId: number) {
    return this.httpService.post<BackendResponse<Invitation>>(`${this.url}/invitations/accept`, {
      id: invitationId,
    });
  }

  resetWorkspace() {
    return this.httpService
      .get<BackendResponse<Workspace[]>>(`${this.url}/workspaces`)
      .pipe(takeUntilDestroyed(this.destroyRef), first())
      .subscribe(val => {
        this.workspacesListener.next(val.result);
      });
  }

  analytics() {
    return this.httpService.get<BackendResponse<any>>(`${this.url}/workspaces/analytics`);
  }
}
