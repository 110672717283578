import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  DestroyRef,
  HostListener,
  inject,
  signal,
  ViewChild,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from "@angular/router";

import { AvatarMetaComponent } from "@common/avatar-meta/avatar-meta.component";
import { MainTitleComponent } from "@common/main-title/main-title.component";
import { NoDataComponent } from "@common/no-data/no-data.component";
import { TooltipService } from "@common/tooltip.service";
import { ImgFallbackDirective } from "@directives/img-fallback.directive";
import { ScrollDirective } from "@directives/scroll.directive";
import { Company, Invitation, Notification, User, Workspace } from "@models/models";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import {
  iconoirAntenna,
  iconoirAntennaOff,
  iconoirBell,
  iconoirBuilding,
  iconoirCancel,
  iconoirFastArrowLeft,
  iconoirFastArrowRight,
  iconoirFrameAltEmpty,
  iconoirGroup,
  iconoirHome,
  iconoirHorizontalSplit,
  iconoirHouseRooms,
  iconoirJournalPage,
  iconoirLogOut,
  iconoirMap,
  iconoirMediaImageFolder,
  iconoirMenu,
  iconoirMessage,
  iconoirMultiplePages,
  iconoirNavArrowLeft,
  iconoirNetwork,
  iconoirOpenBook,
  iconoirSearch,
  iconoirUser,
} from "@ng-icons/iconoir";
import { ionChevronDownOutline, ionDiscOutline } from "@ng-icons/ionicons";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { SplitViewComponent } from "@pages/private/split-view/split-view.component";
import { FormatDatePipe } from "@pipes/format-date.pipe";
import { MediaUrlPipe } from "@pipes/media-url.pipe";
import { AuthService } from "@services/auth.service";
import { BackendService } from "@services/backend.service";
import { LocalStorageService } from "@services/local-storage.service";
import { MessageService } from "@services/message.service";
import { NetworkStatusService } from "@services/network-status.service";
import { SectorService } from "@services/sector.service";
import { SnackbarService } from "@services/snackbar.service";
import { SplitService } from "@services/split.service";
import { TaskService } from "@services/task.service";
import { TranslationService } from "@services/translation.service";
import { WidthService } from "@services/width.service";
import { WorkspaceService } from "@services/workspace.service";
import { MODULES, PUBLIC_PAGES } from "@variables/globals";
import { CodeInputComponent, CodeInputModule } from "angular-code-input";
import { AngularSplitModule, SplitComponent } from "angular-split";
import { MenuItem } from "primeng/api";
import { BadgeModule } from "primeng/badge";
import { Button, ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import { FocusTrapModule } from "primeng/focustrap";
import { InputTextModule } from "primeng/inputtext";
import { ListboxModule } from "primeng/listbox";
import { OverlayModule } from "primeng/overlay";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { SidebarModule } from "primeng/sidebar";
import { SkeletonModule } from "primeng/skeleton";
import { TieredMenu, TieredMenuModule } from "primeng/tieredmenu";
import { ToastModule } from "primeng/toast";
import { TooltipModule } from "primeng/tooltip";
import { combineLatest, first, map, merge, Subscription } from "rxjs";

interface SearchModel {
  label: string;
  description: string;
  icon: string;
  iconColor?: string;
  command: () => void;
}

@Component({
  selector: "app-root",
  standalone: true,
  imports: [
    CommonModule,
    SidebarModule,
    ButtonModule,
    OverlayModule,
    NgIconComponent,
    TieredMenuModule,
    ListboxModule,
    TranslateModule,
    ToastModule,
    TooltipModule,
    InputTextModule,
    FormsModule,
    NoDataComponent,
    FocusTrapModule,
    DividerModule,
    AngularSplitModule,
    SplitViewComponent,
    MainTitleComponent,
    BadgeModule,
    ProgressSpinnerModule,
    FormatDatePipe,
    DividerModule,
    MediaUrlPipe,
    ImgFallbackDirective,
    AvatarMetaComponent,
    ScrollDirective,
    DropdownModule,
    OverlayPanelModule,
    DialogModule,
    FormsModule,
    CodeInputModule,
    SkeletonModule,
  ],
  providers: [
    provideIcons({
      iconoirMenu,
      iconoirHome,
      iconoirJournalPage,
      iconoirMultiplePages,
      iconoirGroup,
      iconoirBuilding,
      iconoirMessage,
      iconoirUser,
      iconoirBell,
      iconoirNavArrowLeft,
      iconoirMediaImageFolder,
      iconoirLogOut,
      iconoirSearch,
      iconoirFrameAltEmpty,
      iconoirCancel,
      iconoirHorizontalSplit,
      iconoirHouseRooms,
      ionDiscOutline,
      iconoirFastArrowLeft,
      iconoirFastArrowRight,
      iconoirAntennaOff,
      iconoirAntenna,
      iconoirNetwork,
      ionChevronDownOutline,
      iconoirMap,
      iconoirOpenBook,
    }),
  ],
  styleUrls: ["./app.component.scss"],
  templateUrl: "./app.component.html",
})
export class AppComponent implements AfterViewInit {
  @ViewChild("split") splitComponent!: SplitComponent;
  @ViewChild("op") tieredMenu!: TieredMenu;
  @ViewChild("opButton") opButton: Button;
  @ViewChild("codeInputComponent") codeInputComponent: CodeInputComponent;

  private readonly isFirstVisit = signal(true);
  private readonly destroyRef = inject(DestroyRef);
  private readonly authService = inject(AuthService);
  private readonly backendService = inject(BackendService);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly translationService = inject(TranslationService);
  private readonly snackbarService = inject(SnackbarService);
  private readonly taskService = inject(TaskService);
  private readonly tooltipService = inject(TooltipService);
  private readonly messageService = inject(MessageService);
  private readonly sectorService = inject(SectorService);

  private splitSubscription: Subscription | undefined;
  private possibleOptions: SearchModel[] = [];

  readonly translateService = inject(TranslateService);
  readonly splitService = inject(SplitService);
  readonly widthService = inject(WidthService);
  readonly routerService = inject(Router);
  readonly networkService = inject(NetworkStatusService);
  readonly activatedRouteService = inject(ActivatedRoute);
  readonly workspaceService = inject(WorkspaceService);

  options: SearchModel[] = [];

  searchValue = "";
  fullSidebar = false;
  showSide = false;
  url = "/";
  bodyFull = false;
  notificationVisible = false;
  searchOverlayVisible = false;

  notifications: Notification[] = [];
  notificationLoading = false;
  notificationPage = 1;
  notificationLimit = 10;
  notificationTotal = 0;
  notificationUnread = 0;

  user?: User;
  company?: Company;
  menuItems: (MenuItem & { key?: string })[] = [];
  routes: { name: string; icon: string; link: string }[] = [];

  sidebarText = true;
  loading = false;
  online = false;

  hasChatNotification = false;
  openedNotification = false;
  translation: any = {};
  workspaces: Workspace[] = [];
  workspaceAdd = false;
  workspaceLoading = false;
  acceptLoading = false;
  invitation?: Invitation;

  inEducation = false;

  constructor() {
    this.widthService.checkWidth();
    this.widthService.bodyFull$.pipe(takeUntilDestroyed()).subscribe(val => (this.bodyFull = val));
    this.authService.company.pipe(takeUntilDestroyed()).subscribe(val => (this.company = val));
    this.authService.user.pipe(takeUntilDestroyed()).subscribe(val => {
      if (val?.id) {
        this.user = val;
      }
    });
    this.messageService
      .listenNewMessages()
      .pipe(takeUntilDestroyed())
      .subscribe(val => {
        if (val.user?.id !== this.user?.id) {
          this.snackbarService.info(
            val.message,
            (val.user?.firstName ?? "") +
              " " +
              (val.user?.lastName ?? "") +
              this.translation["page.app.has-message"],
            undefined,
            val
          );
          let activeRoomId = 0;
          const path = window.location.pathname;
          const match = path.match(/^\/messages\/(\d+)/);
          if (match) activeRoomId = +match[1];
          if (activeRoomId !== val.roomId) {
            this.messageService.addMessage(val);
          }
        }
      });
    this.workspaceService.workspaces
      .pipe(takeUntilDestroyed())
      .subscribe(val => (this.workspaces = val));

    if (this.translationService.getCurrentLanguage())
      this.translateService.use(this.translationService.getCurrentLanguage());

    this.sectorService.education
      .pipe(takeUntilDestroyed())
      .subscribe(val => (this.inEducation = val));

    combineLatest({
      userIdMessages: this.messageService.userIdMessages,
      chatRooms: this.messageService.chatRooms,
    })
      .pipe(takeUntilDestroyed())
      .subscribe(({ userIdMessages, chatRooms }) => {
        const messageCount = chatRooms.reduce((pre, cur) => {
          const found = userIdMessages.find(message => message.roomId === cur.id);
          return pre + (found?.lastRead !== cur.lastMessage?.id ? 1 : 0);
        }, 0);
        const filtered = this.notifications.filter(a => a.title !== "Unread chats");
        if (messageCount > 0) {
          filtered.unshift({
            id: 1,
            title: "Unread chats",
            description: `You have total of ${messageCount} new messages`,
            data: JSON.stringify({
              type: "messages",
            }),
            createdAt: new Date().toISOString(),
          });
          this.hasChatNotification = true;
        } else this.hasChatNotification = false;
        this.notifications = filtered;
      });

    this.workspaceService.active.pipe(takeUntilDestroyed()).subscribe(() => {
      this.notificationLoading = true;
      this.notificationPage = 1;
      this.backendService
        .getNotifications(this.notificationPage, this.notificationLimit)
        .pipe(takeUntilDestroyed(this.destroyRef), first())
        .subscribe({
          error: () => {
            this.notificationLoading = false;
          },
          next: resp => {
            this.notificationLoading = false;
            if (resp.message === "success") {
              this.notificationTotal = resp.result.meta.totalItems;
              this.notifications = resp.result.items ?? [];
              this.notificationUnread = (this.notifications ?? []).reduce(
                (pre, cur) => pre + +!cur.isSeen,
                0
              );
            }
          },
        });
    });

    combineLatest({
      translation: this.translationService.get("page.app"),
      education: this.sectorService.educationListener,
    })
      .pipe(takeUntilDestroyed())
      .subscribe(({ translation: val, education }) => {
        this.possibleOptions = [
          {
            label: val.sidebar["home"],
            description: val.navigation["to-home"],
            icon: "iconoirFrameAltEmpty",
            command: () => {
              if (this.networkService.isOnline()) this.routerService.navigate([""]);
            },
          },
          {
            label: val.sidebar["workspace"],
            description: val.navigation["to-workspace"],
            icon: "iconoirFrameAltEmpty",
            command: () => {
              if (this.networkService.isOnline()) this.routerService.navigate(["workspace"]);
            },
          },
          {
            label: val.sidebar["opportunities"],
            description: val.navigation["to-opportunities"],
            icon: "iconoirFrameAltEmpty",
            command: () => {
              if (this.networkService.isOnline()) this.routerService.navigate(["opportunities"]);
            },
          },
          {
            label: val.sidebar["tasks"],
            description: val.navigation["to-tasks"],
            icon: "iconoirFrameAltEmpty",
            command: () => {
              if (this.networkService.isOnline()) this.routerService.navigate(["tasks"]);
            },
          },
          {
            label: val.sidebar["content"],
            description: val.navigation["to-content"],
            icon: "iconoirFrameAltEmpty",
            command: () => {
              if (this.networkService.isOnline()) this.routerService.navigate(["content"]);
            },
          },
          {
            label: val.sidebar["profile"],
            description: val.navigation["to-profile"],
            icon: "iconoirFrameAltEmpty",
            command: () => {
              if (this.networkService.isOnline()) this.routerService.navigate(["profile"]);
            },
          },
          {
            label: val.sidebar["messages"],
            description: val.navigation["to-messages"],
            icon: "iconoirFrameAltEmpty",
            command: () => {
              if (this.networkService.isOnline()) this.routerService.navigate(["messages"]);
            },
          },
          {
            label: val.sidebar["logout"],
            description: val.navigation["logout"],
            icon: "iconoirLogOut",
            iconColor: "text-prime-danger",
            command: () => {
              if (this.networkService.isOnline()) this.authService.logout();
            },
          },
        ];
        if (education) {
          this.possibleOptions.splice(4, 0, {
            label: val.sidebar["roadmap"],
            description: val.navigation["to-roadmap"],
            icon: "iconoirFrameAltEmpty",
            command: () => {
              if (this.networkService.isOnline()) this.routerService.navigate(["roadmap"]);
            },
          });
          this.routes = MODULES.filter(module => module.name !== "collaborators").map(module => ({
            ...module,
            name:
              module.name !== "user-list" ? val.sidebar[module.name] : val.sidebar["student-list"],
          }));
        } else {
          this.possibleOptions.splice(4, 0, {
            label: val.sidebar["collaborators"],
            description: val.navigation["to-collaborators"],
            icon: "iconoirFrameAltEmpty",
            command: () => {
              if (this.networkService.isOnline()) this.routerService.navigate(["collaborators"]);
            },
          });
          this.routes = MODULES.filter(
            module => !["roadmap", "questionnaire"].includes(module.name)
          ).map(module => ({
            ...module,
            name: val.sidebar[module.name],
          }));
        }
        this.options = this.possibleOptions.slice(0, 5);
      });

    combineLatest({
      company: this.authService.company,
      translate: merge(
        this.translateService
          .get([
            "common.logout",
            "page.app.sidebar.profile",
            "page.app.sidebar.company",
            "page.app.sidebar.workspace",
            "page.app.sidebar.settings",
            "page.workspace.add",
            "page.app.has-message",
            "languages",
          ])
          .pipe(takeUntilDestroyed(), first()),
        this.translateService.onLangChange.pipe(
          takeUntilDestroyed(),
          map(val => ({
            "common.logout": val.translations["common"]["logout"],
            "page.app.sidebar.profile": val.translations["page"]["app"]["sidebar"]["profile"],
            "page.app.sidebar.company": val.translations["page"]["app"]["sidebar"]["company"],
            "page.app.sidebar.workspace": val.translations["page"]["app"]["sidebar"]["workspace"],
            "page.app.sidebar.settings": val.translations["page"]["app"]["sidebar"]["settings"],
            "page.workspace.add": val.translations["page"]["workspace"]["add"],
            "page.app.has-message": val.translations["page"]["app"]["has-message"],
            languages: val.translations["languages"],
          }))
        )
      ),
      workspaces: this.workspaceService.workspaces,
    })
      .pipe(takeUntilDestroyed())
      .subscribe(({ company, translate, workspaces }) => {
        this.company = company;
        this.translation = translate;
        this.workspaces = workspaces;
      });
    this.routerService.events.pipe(takeUntilDestroyed()).subscribe(ev => {
      if (ev instanceof NavigationStart) {
        this.loading = true;
      } else if (ev instanceof NavigationEnd) {
        this.loading = false;
        this.fullSidebar = false;
        if (PUBLIC_PAGES.every(page => !ev.url.startsWith(page))) {
          this.clearHighlightedOption();
          this.showSide = true;
          this.url = ev.url;
          if (this.isFirstVisit()) {
            this.openedNotification = !this.notificationUnread;
            this.authService.getUserFromToken();
            this.isFirstVisit.update(isFirstVisit => !isFirstVisit);
          }
        } else this.showSide = false;
      }
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.widthService.checkWidth();
    if (this.widthService.getWidth() >= 1200) {
      this.splitService.clearSplit();
    }
  }

  @HostListener("window:online", ["$event"])
  onOnline() {
    this.networkService.setOnline(true);
    this.menuDisabled(["profile", "language"], false);
    this.snackbarService.online();
  }

  @HostListener("window:offline", ["$event"])
  onOffline() {
    this.networkService.setOnline(false);
    this.menuDisabled(["profile", "language"], true);
    this.snackbarService.offline();
  }

  ngAfterViewInit() {
    this.activatedRouteService.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(val => {
        if (val["changeLanguage"]) {
          setTimeout(() => {
            document.getElementById("opButton")?.click();
            const option = this.menuItems.find(item => item["key"] === "language");
            if (option) option.styleClass = "bg-primary-light";
            setTimeout(() => {
              const menuItem = document.getElementById("language-option");
              if (menuItem) {
                const { top, left } = menuItem.getBoundingClientRect();
                this.tooltipService.showTooltip("language", "language", true, {
                  top: top + 6,
                  left: left - 120,
                });
              }
            }, 500);
          }, 500);
        }
      });
  }

  showMenu() {
    this.menuItems = [
      {
        label: this.translation?.["common.logout"],
        command: () => this.authService.logout(),
      },
    ];
    if (this.company?.id) {
      this.menuItems = [
        {
          label: this.translation?.["page.app.sidebar.profile"],
          escape: false,
          key: "profile",
          disabled: !this.networkService.isOnline(),
          url: "/profile",
        },
        {
          label: this.translation?.["page.app.sidebar.settings"],
          escape: false,
          key: "settings",
          command: () => {
            if (this.networkService.isOnline()) {
              this.routerService.navigate(["settings"]);
            }
          },
        },
        {
          label: this.translation?.["languages"]["change"],
          key: "language",
          id: "language-option",
          disabled: !this.networkService.isOnline(),
          items: [
            {
              label: this.translation?.["languages"]["en"],
              command: () => {
                if (this.networkService.isOnline()) {
                  this.translateService.use("en");
                  this.changeLanguageCommonTask();
                  setTimeout(() => {
                    this.localStorageService.saveData("lang", "en");
                  }, 1000);
                }
              },
            },
            {
              label: this.translation?.["languages"]["mn"],
              command: () => {
                if (this.networkService.isOnline()) {
                  this.translateService.use("mn");
                  this.changeLanguageCommonTask();
                  setTimeout(() => {
                    this.localStorageService.saveData("lang", "mn");
                  }, 1000);
                }
              },
            },
          ],
        },
        {
          separator: true,
        },
        {
          label: this.translation?.["common.logout"],
          key: "logout",
          command: () => {
            setTimeout(() => {
              this.workspaceService.clear();
              this.authService.logout(true);
            }, 500);
            this.tieredMenu.hide();
          },
        },
        {
          separator: true,
        },
        {
          label: "v1.0.7",
          disabled: true,
        },
      ];
      const submenuItems: MenuItem[] = [
        {
          label: `${this.translation?.["page.app.sidebar.company"]}: ${this.company?.name}`,
          style: this.workspaceService.active.value?.company
            ? {
                background: "var(--primary-DEFAULT)",
              }
            : {},
          command: () => {
            if (this.networkService.isOnline()) this.workspaceService.switchWorkspace(null);
          },
        },
      ];
      this.workspaces.forEach(workspace => {
        submenuItems.push({
          label: `${this.translation?.["page.app.sidebar.workspace"]}: ${workspace.name}`,
          style:
            !this.workspaceService.active.value?.company &&
            this.workspaceService.active.value?.id === workspace.id
              ? {
                  background: "var(--primary-DEFAULT)",
                }
              : {},
          command: () => {
            if (this.networkService.isOnline()) this.workspaceService.switchWorkspace(workspace);
          },
        });
      });
      submenuItems.push({
        label: this.translation?.["page.workspace.add"],
        command: () => {
          if (this.networkService.isOnline()) this.workspaceAdd = true;
        },
      });

      this.menuItems.splice(3, 0, {
        label: this.translation?.["page.app.sidebar.workspace"],
        key: "workspace",
        id: "workspace-option",
        disabled: !this.networkService.isOnline(),
        items: submenuItems,
      });
    }
  }

  changeLanguageCommonTask() {
    this.taskService.finishCommonTask("changeLanguage");
  }

  clearHighlightedOption(redirect?: boolean) {
    if (redirect && window.location.pathname === "/")
      this.routerService.navigate([""], { queryParams: {} });
    const option = this.menuItems.find(item => item["key"] === "language");
    if (option) option.styleClass = "bg-white";
    // this.tieredMenu?.hide();
    this.tooltipService.hideTooltip("language");
  }

  menuDisabled(key: string | string[], disabled: boolean) {
    if (!Array.isArray(key))
      this.menuItems = this.menuItems.map(item => {
        if (item.key === key)
          return {
            ...item,
            disabled,
          };
        return item;
      });
    else
      this.menuItems = this.menuItems.map(item => {
        if (item.key && key.includes(item.key))
          return {
            ...item,
            disabled,
          };
        return item;
      });
  }

  search() {
    if (this.searchValue)
      this.options = this.possibleOptions
        .filter(
          val =>
            val.label.toLowerCase().includes(this.searchValue.toLowerCase()) ||
            val.description.toLowerCase().includes(this.searchValue.toLowerCase())
        )
        .slice(0, 5);
    else this.options = this.possibleOptions.slice(0, 5);
  }

  changeOverlay(an: any, type?: string) {
    if (type === "hide") {
      this.searchValue = "";
      setTimeout(() => {
        this.search();
        this.searchOverlayVisible = false;
      }, 200);
    }
  }

  navigate(link: string) {
    if (this.company?.id) {
      this.routerService.navigate([link]);
    }
  }

  removeSubscription() {
    if (this.splitSubscription) {
      this.splitSubscription.unsubscribe();
      this.splitSubscription = undefined;
    }
  }

  iconClick() {
    if (this.widthService.getWidth() >= 576) {
      if (this.networkService.isOnline()) this.routerService.navigate([""]);
    } else {
      this.fullSidebar = true;
    }
  }
  navigateNotification(notification: Notification) {
    const parsed = JSON.parse(notification.data ?? "");
    if (parsed) {
      switch (parsed["type"]) {
        case "application":
          this.routerService.navigate(["opportunities", parsed["opportunityId"]]);
          this.splitService.splitWithSize([
            "opportunities",
            parsed["opportunityId"],
            parsed["applicationId"],
          ]);
          break;
        case "workspace_member":
          this.routerService.navigate(["workspace", parsed["workspaceId"], "members"], {
            queryParams: { activeIndex: 1 },
          });
          break;
        case "messages":
          this.routerService.navigate(["messages"]);
          break;
        case "task":
          if (this.inEducation)
            this.splitService.splitWithSize(["tasks", "student", parsed["data"]]);
          else this.splitService.splitWithSize(["tasks", parsed["data"]]);
          break;
        case "link":
          window.open(parsed["data"], "_blank");
          break;
        case "opportunities":
          this.routerService.navigate(["opportunities", parsed["data"]]);
          break;
        case "communityJoinRequest":
          this.routerService.navigate(["community", parsed["data"]], {
            queryParams: { activeIndex: 1 },
          });
          break;
        case "community":
          this.routerService.navigate(["community", parsed["data"]]);
          break;
        case "forum":
          this.splitService.splitWithSize(["forum", parsed["data"]]);
          break;
        case "forumComment":
          this.splitService.splitWithSize(["forum", parsed["data"]["forumId"]]);
          break;
        case "forumCommentReply":
          this.splitService.splitWithSize(["forum", parsed["data"]["forumId"]]);
          break;
      }
      if (!notification.isSeen) {
        this.notifications = this.notifications.map(a => {
          if (a.id !== notification.id) return a;
          return {
            ...notification,
            isSeen: true,
          };
        });
        this.notificationUnread -= 1;
      }
      if (parsed["type"] !== "messages")
        this.backendService
          .getSingleNotification(notification.id)
          .pipe(takeUntilDestroyed(this.destroyRef), first())
          .subscribe(val => console.log(val));
    }
  }

  onCodeCompleted(code: string) {
    this.workspaceLoading = true;
    if (code.length === 6) {
      this.workspaceService
        .getInvitationFromCode(code)
        .pipe(takeUntilDestroyed(this.destroyRef), first())
        .subscribe({
          error: () => (this.workspaceLoading = false),
          next: val => {
            this.workspaceLoading = false;
            if (val.message === "success") {
              if (this.invitation?.role !== "student") this.invitation = val.result;
            }
          },
        });
    }
  }

  accept() {
    this.acceptLoading = true;
    if (this.invitation?.id) {
      this.workspaceService
        .acceptCode(this.invitation?.id)
        .pipe(takeUntilDestroyed(this.destroyRef), first())
        .subscribe({
          error: () => (this.acceptLoading = false),
          next: val => {
            this.acceptLoading = false;
            if (val.message === "success") {
              this.workspaceService.resetWorkspace();
              this.codeInputComponent.reset();
              this.invitation = undefined;
              this.workspaceAdd = false;
            }
          },
        });
    }
  }

  notificationClick(val: any) {
    if (val?.data && val?.data?.roomId) {
      this.routerService.navigate(["messages", val?.data?.roomId]);
      this.splitService.clearSplit();
      this.snackbarService.clear(val?.data?.id);
    }
  }
}
