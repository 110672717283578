<div
  class="meta-container"
  appendTo="body"
  tooltipStyleClass="max-w-[250px]"
  tooltipPosition="top"
  [style]="{ display: 'flex', 'flex-direction': 'row', 'margin-bottom': '0.25rem' }"
  [ngClass]="[imgSize ?? size, !customDescription ? 'items-center' : '', containerClass]"
  [pTooltip]="tooltip ? title : ''"
>
  <ng-container *ngIf="avatar || !defaultAvatar">
    <p-avatar
      alt="image"
      shape="circle"
      [ngClass]="
        (imgSize ?? size) === 'full'
          ? 'overflow-hidden rounded-full object-cover h-full w-full'
          : 'overflow-hidden rounded-full object-cover'
      "
      [styleClass]="'flex items-center' + ' ' + (imgSize ?? size) + ' '"
      [image]="
        avatar
          ? (avatar | mediaUrl)
          : fallBackLabel
          ? undefined
          : type === 'company'
          ? '/assets/logo/empty-company.svg'
          : type === 'user'
          ? '/assets/logo/empty-user.svg'
          : '/assets/logo/empty-community.svg'
      "
      [label]="!avatar && fallBackLabel ? fallBackLabel : undefined"
      [size]="'large'"
      [style]="{
        backgroundColor: fallBackLabel ? color : 'transparent',
        color: 'var(--black)'
      }"
      (onImageError)="error($event)"
    />
  </ng-container>
  <ng-container *ngIf="!avatar && defaultAvatarHtml">
    <!-- <p class="single-line">{{ defaultAvatarHtml }}</p> -->
    <div
      [innerHTML]="defaultAvatarHtml"
      [ngClass]="'p-avatar bg-transparent ' + (imgSize ?? size)"
    ></div>
  </ng-container>
  <div
    *ngIf="extra || description || title || custom"
    class="meta-label-container flex gap-x-1 no-underline"
    [ngClass]="['flex-' + labelDirection, imgSize ?? size, labelClass]"
  >
    <div class="flex flex-col" [ngClass]="labelContainerClass">
      <span
        *ngIf="extra !== '' && customDescription"
        class="meta-description block font-thin text-secondary no-underline"
        [ngClass]="[
          labelDirection === 'row' ? 'underline max-w-[200px] single-line' : '',
          size,
          extraClass
        ]"
      >
        {{ extra }}
      </span>
      <span
        class="meta-title block font-semibold no-underline"
        [ngClass]="[labelDirection === 'row' ? 'max-w-[200px] single-line' : '', size, titleClass]"
      >
        {{ title }}
      </span>
    </div>
    <div *ngIf="!customDescription; else custom" class="flex flex-row justify-between gap-x-2">
      <span
        *ngIf="description !== ''"
        class="meta-description block font-thin text-secondary no-underline"
        [ngClass]="[
          labelDirection === 'row' ? 'underline max-w-[200px] single-line' : '',
          size,
          descriptionClass
        ]"
      >
        {{ description }}
      </span>
      <span
        *ngIf="extra !== ''"
        class="meta-description block font-thin text-secondary no-underline"
        [ngClass]="[
          labelDirection === 'row' ? 'underline max-w-[200px] single-line' : '',
          size,
          extraClass
        ]"
      >
        {{ extra }}
      </span>
    </div>
  </div>
</div>

<ng-template #custom>
  <ng-content />
</ng-template>
