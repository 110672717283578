import { Injectable } from "@angular/core";

import { AuthService } from "@services/auth.service";
import { environment } from "@src/environments/environment";
import { Socket } from "ngx-socket-io";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class CustomSocket extends Socket {
  private tokenSubject: BehaviorSubject<string>;

  constructor(private authService: AuthService) {
    const initialToken = authService.getToken();

    super({
      url: environment.websocket,
      options: {
        extraHeaders: {
          authorization: `Bearer ${initialToken}`,
        },
        autoConnect: true,
      },
    });

    this.tokenSubject = new BehaviorSubject(initialToken);

    this.authService.accessToken.subscribe((newToken: string) => {
      if (newToken !== this.tokenSubject.value) {
        this.tokenSubject.next(newToken);
        this.updateSocketAuthorization(newToken);
        this.disconnect();
        this.connect();
      }
    });
  }

  private updateSocketAuthorization(newToken: string): void {
    this.ioSocket.io.opts.extraHeaders = {
      authorization: `Bearer ${newToken}`,
    };
  }
}
