import { HttpClient } from "@angular/common/http";
import { DestroyRef, inject, Injectable, OnDestroy } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";

import {
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from "@abacritt/angularx-social-login";
import { cache } from "@interceptors/cache.interceptor";
import { BackendResponse, Company, Token, User } from "@models/models";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "@src/environments/environment";
import { AES } from "crypto-js";
import jwtDecode from "jwt-decode";
import { ConfirmationService } from "primeng/api";
import { BehaviorSubject, first, firstValueFrom, map, of, tap } from "rxjs";

import { LocalStorageService } from "./local-storage.service";
import { SnackbarService } from "./snackbar.service";
import { TranslationService } from "./translation.service";

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  private readonly httpService = inject(HttpClient);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly snackbarService = inject(SnackbarService);
  private readonly socialAuthService = inject(SocialAuthService);
  private readonly routerService = inject(Router);
  private readonly destroyRef = inject(DestroyRef);
  private readonly translateService = inject(TranslateService);
  private readonly translationService = inject(TranslationService);
  private readonly confirmationService = inject(ConfirmationService);

  private isLoggedInListener = new BehaviorSubject<boolean>(false);
  private userListener = new BehaviorSubject<User | undefined>(undefined);
  private companyListener = new BehaviorSubject<Company | undefined>(undefined);

  private accessTokenExpiration = new BehaviorSubject<number>(0);
  private refreshTokenExpiration = new BehaviorSubject<number>(0);

  private accessTokenTimer?: ReturnType<typeof setTimeout>;
  private refreshTokenTimer?: ReturnType<typeof setTimeout>;

  accessToken = new BehaviorSubject<string>("");
  refreshToken = new BehaviorSubject<string>("");

  isLoggedIn = this.isLoggedInListener.asObservable();
  user = this.userListener.asObservable();
  company = this.companyListener.asObservable();

  translations: Record<string, string> = {};

  constructor() {
    this.translationService
      .get("dialog")
      .pipe(takeUntilDestroyed())
      .subscribe(val => {
        this.translations = val;
      });

    this.refreshToken.next(this.localStorageService.getData("refresh") ?? "");
    this.userListener.next(JSON.parse(this.localStorageService.getData("user") ?? "{}"));

    this.socialAuthService.authState.pipe(takeUntilDestroyed()).subscribe((user: SocialUser) => {
      if (user) {
        this.socialAuthService
          .getAccessToken(GoogleLoginProvider.PROVIDER_ID)
          .then((token: any) => {
            firstValueFrom(
              this.httpService.post<any>(
                `${environment.backendUrl}/auth/google-login`,
                {
                  token,
                },
                {
                  headers: { "no-error-message": "true" },
                }
              )
            )
              .then(res => {
                this.loginWithToken(res, true);
              })
              .catch((e: any) => {
                if (e.message === "Server error NotFoundException: User not found")
                  this.confirmationService.confirm({
                    message: this.translations["would-mail"],
                    header: this.translations["confirmation"],
                    accept: () => {
                      const hash = AES.encrypt(user.email, environment.encryptionKey);
                      this.routerService.navigate(["signup"], {
                        queryParams: { "email-token": hash.toString() },
                      });
                    },
                  });
                else this.clearAll(true);
              });
          });
      }
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this.accessTokenTimer);
    clearTimeout(this.refreshTokenTimer);
  }

  getCompanyValue() {
    return this.companyListener.value;
  }

  getUserValue() {
    return this.userListener.value;
  }

  async signup(
    data: Pick<User, "email" | "firstName" | "lastName"> & {
      password: string;
      invitationId?: number;
    }
  ) {
    try {
      const user = await firstValueFrom(
        this.httpService.post<BackendResponse<User>>(
          `${environment.backendUrl}/users/enterprise`,
          data,
          { headers: { "no-error-message": "true" } }
        )
      );
      this.translateService
        .get("snackbar")
        .pipe(takeUntilDestroyed(this.destroyRef), first())
        .subscribe(val => {
          if (user.message === "success") {
            this.snackbarService.success(val["create-success"]);
            setTimeout(() => this.routerService.navigate(["login"]), 1000);
          }
        });
    } catch (e) {
      this.clearAll(true);
      // @ts-ignore
      throw new Error(e);
    }
  }

  async login(data: Pick<User, "email"> & { password: string }) {
    try {
      const resp = await firstValueFrom(
        this.httpService.post<Token & { user: User }>(
          `${environment.backendUrl}/auth/login`,
          data,
          { headers: { "no-error-message": "true", "no-authorization": "true" } }
        )
      );
      this.loginWithToken(resp);
    } catch (e) {
      this.clearAll(true);
      // @ts-ignore
      throw new Error(e);
    }
  }
  loginWithToken(resp: any, thirdParty?: boolean) {
    if (resp.accessToken && resp.refreshToken) {
      if (resp.user.type !== "enterprise") {
        if (thirdParty)
          this.translateService
            .get("snackbar")
            .pipe(takeUntilDestroyed(this.destroyRef), first())
            .subscribe(val => {
              this.snackbarService.error(val["only-enterprise-user"]);
            });
        else throw new Error("Individual user");
      } else {
        // Access token
        this.accessToken.next(resp.accessToken);
        const decodedAccessToken = jwtDecode<{ exp: number }>(resp.accessToken);
        this.accessTokenExpiration.next(decodedAccessToken.exp * 1000);
        this.saveAccessToken(resp.accessToken, decodedAccessToken.exp * 1000);

        // Refresh token
        this.refreshToken.next(resp.refreshToken);
        const decodedRefreshToken = jwtDecode<{ exp: number }>(resp.refreshToken);
        this.refreshTokenExpiration.next(decodedRefreshToken.exp * 1000);
        this.saveRefreshToken(resp.refreshToken, decodedRefreshToken.exp * 1000);

        // User
        this.userListener.next(resp.user);
        this.saveUser(resp.user);

        // Company
        this.companyListener.next(resp.user.enterpriseUser?.company);
        this.saveCompany(resp.user.enterpriseUser?.company);
        if (resp.user.enterpriseUser?.company) {
          this.routerService.navigate([""]);
        } else {
          this.routerService.navigate(["company"]);
        }
      }
    } else {
      this.clearAll(true);
    }
  }

  logout(fullClear?: boolean) {
    this.clearAll(fullClear);
    cache.clear();
    setTimeout(() => {
      if (this.routerService.url !== "/login") {
        this.routerService.navigate(["login"]);
      }
      return this.httpService.get(`${environment.backendUrl}/auth/logout`);
    }, 500);
  }

  setAuthTimer(duration: number) {
    clearTimeout(this.accessTokenTimer);
    this.accessTokenTimer = setTimeout(() => {
      this.refresh().subscribe({
        error: () => this.logout(),
        next: val => {
          if (val.accessToken) {
            clearTimeout(this.accessTokenTimer);
            this.accessToken.next(val.accessToken);
            const decodedAccessToken = jwtDecode<{ exp: number }>(val.accessToken);
            this.accessTokenExpiration.next(decodedAccessToken.exp * 1000);
            this.saveAccessToken(val.accessToken, decodedAccessToken.exp * 1000);
            this.setAuthTimer(decodedAccessToken.exp * 1000 - new Date().getTime());
          }
        },
      });
    }, duration * 0.9);
  }

  setRefreshTokenTimer(duration: number) {
    clearTimeout(this.refreshTokenTimer);
    this.refreshTokenTimer = setTimeout(() => {
      this.logout();
    }, duration);
  }

  forgotPassword(email: string) {
    return this.httpService.post<BackendResponse<null>>(
      `${environment.backendUrl}/auth/forgot-password`,
      {
        email,
      }
    );
  }

  resetPassword(password: string, token: string) {
    return this.httpService.post<BackendResponse<null>>(
      `${environment.backendUrl}/auth/reset-password`,
      {
        newPassword: password,
        resetToken: token,
      }
    );
  }

  updateUser(
    id: number,
    firstName: string,
    lastName: string,
    avatar?: string,
    phoneNumber?: string
  ) {
    return this.httpService
      .put<BackendResponse<User>>(`${environment.backendUrl}/users/${id}`, {
        firstName,
        lastName,
        avatar,
        phoneNumber,
      })
      .pipe(
        tap(val => {
          if (val.message === "success") {
            this.userListener.next(val.result);
            this.saveUser(val.result);
          }
        })
      );
  }

  autoLogin() {
    const authInfo = this.getAuthInfo();
    if (!authInfo) {
      return false;
    }
    const now = new Date();
    const accessTokenExpiresIn = authInfo.accessTokenExpiresIn - now.getTime();
    if (accessTokenExpiresIn > 0) {
      this.refreshToken.next(authInfo.refresh);
      this.isLoggedInListener.next(true);
      if (JSON.stringify(authInfo.user) !== JSON.stringify(this.userListener.value))
        this.userListener.next(authInfo.user);
      if (JSON.stringify(authInfo.company) !== JSON.stringify(this.companyListener.value))
        this.companyListener.next(authInfo.company);
      // Access Token
      this.accessToken.next(authInfo.access);
      this.accessTokenExpiration.next(authInfo.accessTokenExpiresIn);
      this.setAuthTimer(authInfo.accessTokenExpiresIn - now.getTime());
      // Refresh Token
      this.refreshToken.next(authInfo.refresh);
      this.refreshTokenExpiration.next(authInfo.refreshTokenExpiresIn);
      this.setRefreshTokenTimer(authInfo.refreshTokenExpiresIn - now.getTime());
      return true;
    }
    return false;
  }
  getUserFromToken() {
    this.httpService
      .get<BackendResponse<User>>(`${environment.backendUrl}/users/my/data`)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        error: () => {
          // this.logout();
        },
        next: val => {
          if (val.message === "success") {
            this.userListener.next(val.result);
            this.companyListener.next(val.result.enterpriseUser?.company);
            if (val.result.enterpriseUser?.company)
              this.saveCompany(val.result.enterpriseUser?.company);
            this.saveUser(val.result);
          } else {
            this.logout();
          }
        },
      });
  }

  refresh() {
    this.localStorageService.saveData("access", this.localStorageService.getData("refresh") ?? "");
    return this.httpService
      .get<Token>(`${environment.backendUrl}/auth/refresh`, { headers: { "X-Reset": "true" } })
      .pipe(takeUntilDestroyed(this.destroyRef), first());
  }

  getAuthInfo() {
    const access = this.localStorageService.getData("access") ?? "";
    const refresh = this.localStorageService.getData("refresh") ?? "";
    const parsedUser = JSON.parse(this.localStorageService.getData("user") ?? "{}");
    const user = parsedUser?.id ? parsedUser : undefined;
    const parsedCompany = JSON.parse(this.localStorageService.getData("company") ?? "{}");
    const company = parsedCompany?.id ? parsedCompany : undefined;
    const accessTokenExpiresIn = parseInt(
      this.localStorageService.getData("accessTokenExpiresIn") ?? "0"
    );
    const refreshTokenExpiresIn = parseInt(
      this.localStorageService.getData("refreshTokenExpiresIn") ?? "0"
    );
    if (
      !access ||
      !refresh ||
      !Object.keys(user || {})?.length ||
      !accessTokenExpiresIn ||
      !refreshTokenExpiresIn
    )
      return;
    return {
      access,
      refresh,
      user,
      accessTokenExpiresIn,
      refreshTokenExpiresIn,
      company,
    };
  }

  getCompany(id: number | undefined) {
    if (id) {
      return this.httpService.get<BackendResponse<Company>>(
        `${environment.backendUrl}/companies/${id}`
      );
    } else {
      return of();
    }
  }
  createCompany(data: Company) {
    return this.httpService
      .post<BackendResponse<Company>>(`${environment.backendUrl}/companies`, data)
      .pipe(
        map(value => {
          if (value.message === "success") {
            this.companyListener.next(value.result);
            this.saveCompany(value.result);
          }
          return value;
        })
      );
  }
  editCompany(data: Company, id: number) {
    return this.httpService
      .put<BackendResponse<Company>>(`${environment.backendUrl}/companies/${id}`, data)
      .pipe(
        map(value => {
          if (value.message === "success") {
            this.companyListener.next(value.result);
            this.saveCompany(value.result);
          }
          return value;
        })
      );
  }
  deleteCompany(id: number) {
    return this.httpService
      .delete<BackendResponse<Company>>(`${environment.backendUrl}/companies/${id}`)
      .pipe(
        map(value => {
          if (value.message === "success") {
            this.companyListener.next(undefined);
            this.clearCompany();
          }
          return value;
        })
      );
  }

  changeAccessTokenWorkspace(accessToken: string) {
    this.accessToken.next(accessToken);
    const decodedAccessToken = jwtDecode<{ exp: number }>(accessToken);
    this.accessTokenExpiration.next(decodedAccessToken.exp * 1000);
    this.saveAccessToken(accessToken, decodedAccessToken.exp * 1000);
    const now = new Date();
    this.setAuthTimer(decodedAccessToken.exp * 1000 - now.getTime());
  }
  saveAccessToken(accessToken: string, accessTokenExpiresIn: number) {
    this.localStorageService.saveData("access", accessToken);
    this.localStorageService.saveData("accessTokenExpiresIn", `${accessTokenExpiresIn}`);
  }
  saveRefreshToken(refreshToken: string, refreshTokenExpiresIn: number) {
    this.localStorageService.saveData("refresh", refreshToken);
    this.localStorageService.saveData("refreshTokenExpiresIn", `${refreshTokenExpiresIn}`);
  }
  saveUser(data: User) {
    this.localStorageService.saveData("user", JSON.stringify(data));
  }
  saveCompany(data: Company) {
    this.localStorageService.saveData("company", JSON.stringify(data));
  }
  getToken() {
    return this.accessToken.value;
  }

  clearToken(fullClear?: boolean) {
    this.localStorageService.removeData("access");
    this.localStorageService.removeData("accessTokenExpiresIn");
    if (fullClear) {
      this.localStorageService.removeData("refresh");
      this.localStorageService.removeData("refreshTokenExpiresIn");
    }
  }
  clearUser() {
    this.localStorageService.removeData("user");
  }
  clearCompany() {
    this.localStorageService.removeData("company");
  }
  clearStorage() {
    // content
    this.localStorageService.removeData("content");
    // translations
    this.localStorageService.removeData("eventOpportunity-translations");
    this.localStorageService.removeData("serviceOpportunity-translations");
    this.localStorageService.removeData("exchangeOpportunity-translations");
    this.localStorageService.removeData("generalOpportunity-translations");
    this.localStorageService.removeData("jobOpportunity-translations");
    // community
    this.localStorageService.removeData("community");
    // forum
    this.localStorageService.removeData("forum");
    // opportunity
    this.localStorageService.removeData("eventOpportunity");
    this.localStorageService.removeData("serviceOpportunity");
    this.localStorageService.removeData("exchangeOpportunity");
    this.localStorageService.removeData("generalOpportunity");
    this.localStorageService.removeData("jobOpportunity");
    // payment
    this.localStorageService.removeData("payment");
    // task
    this.localStorageService.removeData("task");
    this.localStorageService.removeData("student-task");
    // workspace-creation
    this.localStorageService.removeData("workspace-creation");
    // messages
    // this.localStorageService.removeData("messages");
  }
  clearAll(fullClear?: boolean) {
    this.clearToken(fullClear);
    this.clearCompany();
    this.clearStorage();
    if (fullClear) {
      this.clearUser();
    }

    this.userListener.next(undefined);
    this.companyListener.next(undefined);
    this.isLoggedInListener.next(false);

    clearTimeout(this.accessTokenTimer);
    clearTimeout(this.refreshTokenTimer);

    this.accessTokenExpiration.next(0);
    this.refreshTokenExpiration.next(0);
  }
}
