import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";

import { environment } from "@src/environments/environment";

import {
  BackendResponse,
  Industry,
  MetaCountBackendResponse,
  Notification,
  Skill,
} from "../models/models";

@Injectable({
  providedIn: "root",
})
export class BackendService {
  private readonly httpService = inject(HttpClient);

  private readonly url = environment.backendUrl;

  getNotifications(page: number, limit: number) {
    return this.httpService.get<MetaCountBackendResponse<Notification>>(
      `${this.url}/notification?page=${page}&limit=${limit}`
    );
  }

  getSingleNotification(id: number) {
    return this.httpService.get<BackendResponse<Notification>>(`${this.url}/notification/${id}`);
  }

  getIndustries() {
    return this.httpService.get<BackendResponse<Industry[]>>(`${this.url}/industries`);
  }
  getSkills() {
    return this.httpService.get<BackendResponse<Skill[]>>(`${this.url}/skills`);
  }
  getLanguages() {
    return this.httpService.get<BackendResponse<Industry[]>>(`${this.url}/languages`);
  }
}
